var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "registered-agent-details-modal",
    attrs: {
      id: "registered-agent-details-modal",
      size: "lg",
      "hide-header-close": true,
      "no-close-on-esc": true,
      "no-close-on-backdrop": true,
    },
    scopedSlots: _vm._u([
      {
        key: "modal-title",
        fn: function () {
          return [
            _c("h3", { staticClass: "m-0 p-0" }, [
              _vm._v("\n      Your Registered Agent Details\n    "),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("h4", { staticClass: "pl-4 pr-4" }, [
              _vm._v("\n      " + _vm._s(_vm.state) + "\n    "),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c("div", { staticClass: "footer-container" }, [
              _c("div", { staticClass: "footer-section-top border-bottom" }, [
                _c("div", { staticClass: "footer-details" }, [
                  _c(
                    "div",
                    {
                      staticClass: "copy-details",
                      attrs: { id: "copyDetails" },
                    },
                    [
                      _c("p", [_vm._v(_vm._s(_vm.name))]),
                      _c("p", [_vm._v(_vm._s(_vm.line1))]),
                      _c("p", [_vm._v(_vm._s(_vm.line2))]),
                      _c("p", [_vm._v(_vm._s(_vm.cityStatePostalCode))]),
                      _c("p", [_vm._v(_vm._s(_vm.county))]),
                      _c("p", [_vm._v(_vm._s(_vm.country))]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "tooltip-container" },
                    [
                      _c("copy-icon", {
                        staticClass: "ml-2 mt-2 copy-icon",
                        on: { click: _vm.copyAddress },
                      }),
                      _c("span", { staticClass: "tooltip-text" }, [
                        _vm._v("Copy Address"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "date-container" }, [
                  _c("div", { staticClass: "date-row" }, [
                    _c("p", { staticClass: "date-label" }, [
                      _vm._v("Start Date:"),
                    ]),
                    _c("p", { staticClass: "date-value" }, [
                      _vm._v(_vm._s(_vm.startDate)),
                    ]),
                  ]),
                  _c("div", { staticClass: "date-row" }, [
                    _c("p", { staticClass: "date-label" }, [
                      _vm._v("End Date:"),
                    ]),
                    _c("p", { staticClass: "date-value" }, [
                      _vm._v(_vm._s(_vm.endDate)),
                    ]),
                  ]),
                ]),
              ]),
              _vm.additionalDetails
                ? _c("span", {
                    staticClass: "footer-section-mid",
                    domProps: { innerHTML: _vm._s(_vm.additionalDetails) },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "footer-section-bottom" },
                [
                  _c("secondary-button", {
                    staticClass: "secondary-btn",
                    attrs: { "button-text": "Find a Pre-filled Form" },
                    on: {
                      onclick: function ($event) {
                        return _vm.navigateToFormsLibrary("forms-library")
                      },
                    },
                  }),
                  _c("secondary-button", {
                    staticClass: "secondary-btn",
                    attrs: { "button-text": "Help Me With My Filing" },
                    on: {
                      onclick: function ($event) {
                        return _vm.navigateToSelectDomesticRegistration(
                          "stageline"
                        )
                      },
                    },
                  }),
                  _c("primary-button", {
                    staticClass: "primary-btn",
                    attrs: {
                      "button-text": "Close",
                      "aria-label": "close registered agent details modal",
                    },
                    on: { onclick: _vm.close },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }